<template>
  <div class="bg-white border border-solid border-grey-light rounded-lg sm:p-7 p-5">
    <div v-if="activitesLoading" class="card-loader"></div>
    <template v-else>
      <p :class="`text-sm opacity-75 ${$i18n.locale === 'en' ? 'tracking-widest' : ''}`">{{ $t('TODAY’S ACTIVITIES') }}</p>
      <div class="md:mt-6 mt-5 grid lg:grid-cols-1 sm:grid-cols-2 grid-cols-1 lg:gap-12 gap-5">
        <!-- Action Needed -->
        <div class="flex items-center justify-between">
          <div @click="isSmallerScreen ? $router.push(`/${$route.params.lang}/merchant/action-center`) : ''" class="flex w-full items-center lg:gap-3 gap-4 lg:border-0 border border-solid border-grey-light rounded-lg lg:px-0 px-4 lg:py-0 py-2">
            <p class="flex justify-center lg:border border-0 border-solid border-grey-light rounded-lg lg:w-auto w-10 lg:px-5 lg:py-4">
              <feather-icon icon="MousePointerIcon" class="text-primary" />
            </p>
            <div class="md:text-lg text-black">
              <p>{{ $t('Action Needed') }}</p>
              <p class="lg:mt-1">{{ todayActivities.action_needed + $t(' orders') }}</p>
            </div>
          </div>
          <router-link :to="`/${$route.params.lang}/merchant/action-center`" class="lg:block hidden material-symbols-rounded text-black font-light cursor-pointer">{{$i18n.locale === 'en' ? 'north_east' : 'north_west'}}</router-link>
        </div>

        <!-- Going To Customers -->
        <div class="flex items-center justify-between">
          <div @click="isSmallerScreen ? $router.push(`/${$route.params.lang}/merchant/orders/delivery-orders?page=1&filter=out_for_delivery`) : ''" class="flex w-full items-center lg:gap-3 gap-4 lg:border-0 border border-solid border-grey-light rounded-lg lg:px-0 px-4 lg:py-0 py-2">
            <p class="flex justify-center lg:border border-0 border-solid border-grey-light rounded-lg lg:w-auto w-10 lg:px-4 lg:py-3">
              <img src="@/assets/images/pages/motorbike.svg" width="31" height="31" alt="">
            </p>
            <div class="md:text-lg text-black">
              <p>{{ $t('Going To Customers') }}</p>
              <p class="lg:mt-1">{{ todayActivities.going_to_customers + $t(' orders') }}</p>
            </div>
          </div>
          <router-link :to="`/${$route.params.lang}/merchant/orders/delivery-orders?page=1&filter=out_for_delivery`" class="lg:block hidden material-symbols-rounded text-black font-light cursor-pointer">{{$i18n.locale === 'en' ? 'north_east' : 'north_west'}}</router-link>
        </div>

        <!-- Delivered To Customers -->
        <div class="flex items-center justify-between">
          <div @click="isSmallerScreen ? $router.push(`/${$route.params.lang}/merchant/orders/delivery-orders?page=1&filter=delivered`) : ''" class="flex w-full items-center lg:gap-3 gap-4 lg:border-0 border border-solid border-grey-light rounded-lg lg:px-0 px-4 lg:py-0 py-2">
            <p class="flex justify-center lg:border border-0 border-solid border-grey-light rounded-lg lg:w-auto w-10 lg:px-5 lg:py-4">
              <feather-icon icon="CheckIcon" class="text-primary" />
            </p>
            <div class="md:text-lg text-black">
              <p>{{ $t('Delivered To Customers') }}</p>
              <p class="lg:mt-1">{{ todayActivities.delivered_to_customers + $t(' orders') }}</p>
            </div>
          </div>
          <router-link :to="`/${$route.params.lang}/merchant/orders/delivery-orders?page=1&filter=delivered`" class="lg:block hidden material-symbols-rounded text-black font-light cursor-pointer">{{$i18n.locale === 'en' ? 'north_east' : 'north_west'}}</router-link>
        </div>

        <!-- Picked Up From You -->
        <div class="flex items-center justify-between">
          <div @click="isSmallerScreen ? $router.push(`/${$route.params.lang}/merchant/orders/delivery-orders?page=1&filter=picked_up`) : ''" class="flex w-full items-center lg:gap-3 gap-4 lg:border-0 border border-solid border-grey-light rounded-lg lg:px-0 px-4 lg:py-0 py-2">
            <p class="flex justify-center lg:border border-0 border-solid border-grey-light rounded-lg lg:w-auto w-10 lg:px-5 lg:py-4">
              <img src="@/assets/images/pages/truck.svg" width="24" height="24" alt="">
            </p>
            <div class="md:text-lg text-black">
              <p>{{ $t('Picked Up From You') }}</p>
              <p class="lg:mt-1">{{ todayActivities.picked_up_from_you + $t(' orders') }}</p>
            </div>
          </div>
          <router-link :to="`/${$route.params.lang}/merchant/orders/delivery-orders?page=1&filter=picked_up`" class="lg:block hidden material-symbols-rounded text-black font-light cursor-pointer">{{$i18n.locale === 'en' ? 'north_east' : 'north_west'}}</router-link>
        </div>

        <!-- Returned To You -->
        <div class="flex lg:flex-row flex-col items-center justify-between">
          <div class="flex w-full items-center lg:gap-3 gap-4 bg-white lg:border-0 border border-solid border-grey-light rounded-lg lg:px-0 px-4 lg:py-0 py-2 z-10">
            <p class="flex justify-center lg:border border-0 border-solid border-grey-light rounded-lg lg:w-auto w-10 lg:px-5 lg:py-4">
              <feather-icon icon="CornerDownLeftIcon" class="text-primary" />
            </p>
            <div class="md:text-lg text-black">
              <p>{{ $t('Returned To You') }}</p>
              <p class="lg:mt-1">{{ todayActivities.returned_to_you + $t(' orders') }}</p>
            </div>
          </div>
          <div class="text-black flex-shrink-0 flex lg:w-auto w-full lg:flex-col flex-row lg:gap-2">
            <div class="flex lg:w-auto w-1/2 lg:items-center gap-1 lg:justify-end justify-center">
              <span class="lg:block hidden border-0 border-b border-solid leading-none">{{ todayActivities.rto + ' ' + $t('RTOs') }}</span>
              <router-link :to="`/${$route.params.lang}/merchant/orders/delivery-orders?page=1&filter=returned`" class="lg:block hidden material-symbols-rounded text-xl font-light text-black cursor-pointer">{{$i18n.locale === 'en' ? 'north_east' : 'north_west'}}</router-link>
              <p style="margin-top: -8px;" class="lg:hidden pt-4 p-2 w-full text-center border border-solid border-grey-light rounded-bl-lg" @click="$router.push(`/${$route.params.lang}/merchant/orders/delivery-orders?page=1&filter=returned`)">{{ $t('RTOs') + ' ' + `(${todayActivities.rto})` }}</p>
            </div>
            <div class="flex lg:w-auto w-1/2 lg:items-center gap-1 lg:justify-end justify-center">
              <span class="lg:block hidden border-0 border-b border-solid leading-none">{{ todayActivities.returns + ' ' + $t('Returns') }}</span>
              <router-link :to="`/${$route.params.lang}/merchant/orders/returns?page=1&filter=returned`" class="lg:block hidden material-symbols-rounded text-xl font-light text-black cursor-pointer">{{$i18n.locale === 'en' ? 'north_east' : 'north_west'}}</router-link>
              <p style="margin-top: -8px;" class="lg:hidden pt-4 p-2 w-full text-center border border-solid border-grey-light rounded-br-lg" @click="$router.push(`/${$route.params.lang}/merchant/orders/returns?page=1&filter=returned`)">{{ $t('Returns') + ' ' + `(${todayActivities.returns})` }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'

export default {
  data () {
    return {
      todayActivities: {
        action_needed: 0,
        going_to_customers: 0,
        delivered_to_customers: 0,
        picked_up_from_you: 0,
        returned_to_you: 0,
        rto: 0,
        returns: 0
      },
      activitesLoading: false
    }
  },
  methods: {
    loadTodayActivities () {
      this.activitesLoading = true
      sendRequest(true, false, this, 'api/v2/analytics/merchant/dashboard/today-activities/', 'get', null, true,
        (response) => {
          this.todayActivities = response.data
          this.activitesLoading = false
        }, (error) => {
          this.activitesLoading = false
          common.notifyResponseError(this, error)
        }
      )
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 992
    }
  },
  created () {
    this.loadTodayActivities()
  }
}
</script>